<template>
  <b-container style="height: 100vh;" class="flex-grow-1">
    <b-row class="h-100">
      <b-col class="mx-auto my-auto" cols="12" md="8" lg="5">
        <div class="d-flex flex-column">
          <h4 class="text-center mt-4">
            Add funds to balance
          </h4>
          <p class="small text-center mb-4 ">You will be redirected to the cryptocloud payment page.</p>

          <tp-icon-input v-model="amount" icon="usdt" placeholder="Amount" class="mb-3" />

          <div class="d-flex">
            <b-btn :disabled="(amount === '' || loading)" @click="startPay" class="button-gradient-primary scale-drawdown-animation w-100 mb-3" pill>
              Pay {{amount}}
              <b-spinner v-if="loading" small />
            </b-btn>
          </div>
        </div>

        <b-link class="link-gray-300" to="/home">
          <b-icon icon="chevron-left" /> Go Home
        </b-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "payments",
  data: () => ({
    amount: '',
    link: '',
    loading: false,
  }),

  methods: {
    startPay() {
      if(!this.loading) {
        this.loading = true

        this.axios(`payment/crypto_cloud_payment/${this.amount}`, {
          method: 'GET',
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')}
        })
            .then(response => {
              this.loading = false
              this.amount = ''
              this.link = response.data.result.link
              window.open(response.data.result.link);
            })
            .catch(() => {
              this.loading = false
            })
      }
    }
  }
}
</script>

<style scoped>

</style>